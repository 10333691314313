<template>
  <SplitPage v-if="authorization && step">
    <template #header>
      <AuthorizationBreadcrumb :active-step="3"/>
    </template>

    <template #left-content>
      <h1 class="text-center font-weight-medium mb-4">Visualisez votre itinéraire</h1>
      <div class="num-step-num-department d-flex justify-content-between">
        <div class="num-step">
          <i class="icon-location"></i> {{ stepNumber }}
        </div>
        <!-- <div class="department">
          <div>{{ stepDepartment }}</div>
        </div> -->
      </div>
      <div class="blocks">
        <div class="block">
          <h3 class="block-title">Localisation</h3>
          <div class="block-content">{{ stepAddress }}</div>
        </div>
        <div class="block">
          <h3 class="block-title">Nom du tronçon</h3>
          <div class="block-content">{{ stepName }}</div>
        </div>
        <div class="block">
          <h3 class="block-title">Prescriptions associées</h3>
          <div class="block-content block-content-big">{{ stepComment }}</div>
        </div>
        <div class="block">
          <h3 class="block-title">Page PDF de référence</h3>
          <div class="block-content block-content-big">{{ stepPageNumber }}</div>
        </div>
      </div>
      <div class="edit-point">
<div v-if="isAdmin">
        <router-link :to="editStepLink"
                     class="btn btn-outline-secondary">
          Modifier le point
        </router-link>
</div>
      </div>
      <div class="prev-next">
        <div class="prev">
          <button @click="prev"
                  :disabled="prevDisabled"
                  class="btn-step-navigation">
            <i class="icon-previous"></i> Précédent
          </button>
        </div>
        <div class="next">
          <button @click="next"
                  :disabled="nextDisabled"
                  class="btn-step-navigation">
            Suivant <i class="icon-next"></i>
          </button>
        </div>
      </div>
      <button v-if="showSubmitForValidationButton"
              @click="submitAuthorizationForValidation"
              class="btn btn-block btn-secondary">
        Soumettre pour validation
      </button>
      <button v-if="showGoToValidationPage"
              @click="goToValidation"
              class="btn btn-block btn-secondary">
        Passer à la validation
      </button>
    </template>

    <template #right-content>
      <AuthorizationMap :view-step="step"
                        :center="center"/>
    </template>
  </SplitPage>
</template>

<script>
import SplitPage from '@/components/SplitPage'
import AuthorizationBreadcrumb from '@/components/AuthorizationBreadcrumb'
import AuthorizationMap from '@/components/AuthorizationMap'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AuthorizationsStepsShowPage',
  components: {
    SplitPage,
    AuthorizationBreadcrumb,
    AuthorizationMap
  },
data () {
	return {
	isAdmin : true
	}
},
  computed: {
    ...mapState({
      authorization: state => state.authorization.authorization,
      steps: state => state.authorization.steps,
      user: state => state.user.user
    }),
    step () {
      return this.steps.length ? this.steps[this.$route.params.num_step - 1] : null
    },
    stepNumber () {
      return this.step.properties.num_step
    },
    stepDepartment () {
      return this.step.properties.num_department || '--'
    },
    stepAddress () {
      return this.step.properties.address || '--'
    },
    stepName () {
      return this.step.properties.name || '--'
    },
    stepComment () {
      return this.step.properties.comment || '--'
    },
    stepPageNumber () {
      return this.step.properties.page_number === null ? '--' : this.step.properties.page_number
    },
    center () {
      return [this.step.properties.latitude, this.step.properties.longitude]
    },
    editStepLink () {
      return {
        name: 'authorizations.steps.edit',
        params: {
          id: this.$route.params.id,
          num_step: this.$route.params.num_step
        }
      }
    },
    prevDisabled () {
      return this.step.properties.way === 0 && this.step.properties.num_step === 1
    },
    nextDisabled () {
      if (this.step.properties.way === 0) {
        return this.step.properties.num_step === this.steps.length
      } else {
        return !this.steps.find(step => {
          return step.properties.way === 1
            && step.properties.num_step === this.step.properties.num_step + 1
        })
      }
    },
    showSubmitForValidationButton () {
      return this.authorization.status === 'ongoing input'
        && this.steps.length >= 2
    },
    showGoToValidationPage () {
      return ['ongoing input', 'waiting for validation'].includes(this.authorization.status)
        && ['manager', 'admin'].includes(this.user.user.access)
    }
  },
  methods: {
    ...mapActions({
      getAuthorization: 'authorization/getAuthorization',
      getSteps: 'authorization/getSteps',
      resetState: 'authorization/resetState',
      submitForValidation: 'authorization/submitAuthorizationForValidation'
    }),
    prev () {
      if (this.prevDisabled) return
      this.$router.push({
        name: 'authorizations.steps.show',
        params: {
          id: this.$route.params.id,
          num_step: parseInt(this.$route.params.num_step) - 1
        }
      })
    },
    next () {
      if (this.nextDisabled) return
      this.$router.push({
        name: 'authorizations.steps.show',
        params: {
          id: this.$route.params.id,
          num_step: parseInt(this.$route.params.num_step) + 1
        }
      })
    },
    submitAuthorizationForValidation () {
      this.submitForValidation().then(() => {
        this.goToValidation()
      })
    },
    goToValidation () {
      this.$router.push({
        name: 'authorizations.validate',
        params: {
          id: this.$route.params.id
        }
      })
    }
  },
  beforeMount() {
    let user = Object.values(JSON.parse(localStorage.getItem('user')))[0].login
    if(user === 'conducteur') {
    this.isAdmin = false
    }
  },
  mounted () {
    if (this.$route.params.num_step === undefined
      || this.$route.params.num_step === null) {
      this.$router.push({ name: '404' })
    }
    this
      .getAuthorization(this.$route.params.id)
      .then(() => this.getSteps(this.$route.params.id))
      .catch(() => this.$router.push({ name: '404' }))
  },
  beforeDestroy () {
    this.resetState()
  }
}
</script>

<style scoped lang="scss">
  .num-step-num-department {
    width: 100%;
    margin-bottom: 30px;
  }

  .num-step {
    font-size: 24px;
    height: 47px;
    line-height: 47px;
    display: flex;
    vertical-align: center;

    @media (max-width: $breakpoint-md) {
      font-size: 14px;
      height: 24px;
      line-height: 24px;
    }

    i {
      color: $red;
      font-size: 47px;
      margin-right: 20px;

      @media (max-width: $breakpoint-md) {
        font-size: 24px;
        margin-right: 10px;
      }
    }
  }

  .blocks {
    margin-bottom: 35px;
    @media (max-width: $breakpoint-md) {
      margin-bottom: 30px;
    }
  }

  .block {
    margin-bottom: 20px;
    @media (max-width: $breakpoint-md) {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  .block-title {
    font-size: 20px;
    font-weight: $font-weight-medium;
    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .block-content-big {
    color: #757575;
    font-size: 16px;
  }

  .edit-point {
    margin-bottom: 50px;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 30px;
    }
  }

  .prev-next {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .prev,
  .next {
    width: 48%;
  }

  .btn-step-navigation {
    color: $gray-text;
    background: $gray-lighter;
    border: none;
    box-shadow: none;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: .5;
    }

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
    }

    @media (max-width: $breakpoint-sm) {
      font-size: 10px;
    }
  }

  .prev .btn-step-navigation {
    text-align: center;
    padding: 13px 15px 13px 10px;

    @media (max-width: $breakpoint-md) {
      padding: 6px 7px 6px 5px;
    }

    @media (max-width: $breakpoint-sm) {
      padding: 6px 0;
    }

    i {
      margin-right: 9px;

      @media (max-width: $breakpoint-md) {
        font-size: 10px;
        margin-right: 7px;
      }
    }
  }

  .next .btn-step-navigation {
    text-align: center;
    padding: 13px 11px 13px 10px;

    @media (max-width: $breakpoint-md) {
      padding: 6px 7px 6px 5px;
    }

    @media (max-width: $breakpoint-sm) {
      padding: 6px 0;
    }

    i {
      margin-left: 11px;

      @media (max-width: $breakpoint-md) {
        font-size: 10px;
        margin-left: 7px;
      }
    }
  }
</style>
